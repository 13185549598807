import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "./index.scss";

Swiper.use([Navigation, Pagination, Autoplay]);

const helpSwiper = new Swiper(".help_slider", {
  slidesPerView: "3.5",
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".pagination_2",
    type: "progressbar",
  },
  breakpoints: {
    1200: {
      slidesPerView: 3.5,
    },
    992: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2.5,
    },
    500: {
      slidesPerView: 2,
    },
    400: {
      slidesPerView: 1.5,
    },
    300: {
      slidesPerView: 1,
    },
  },
});

const bannerSwiper = new Swiper(".banner-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  speed: 1800,
  autoplay: {
    delay: 5000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
